<template>
  <div class="row">
    <div class="col-12">
      <StripePaymentSection
        v-if="!hidePayment"
        :hidePayButton="hidePayButton"
        :hidePayCard="hidePayCard"
        :isUkPayment="isUkPayment"
        :isRecurringPayment="isRecurringPayment"
        :ukDailySadaqahSubscription="ukDailySadaqahSubscription"
        :turnstileReturn="turnstileReturn"
        :checkCard="checkCard"
        :isLoading="isLoadingValue"
        @stripeContinue="stripeContinue"
        @sadaqahComplete="sadaqahComplete"
        @recurringComplete="recurringComplete"
      />
      <StripeInfo />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default ({
  components: {
    StripePaymentSection: defineAsyncComponent(() => import('./views/StripePaymentSection.vue')),
    StripeInfo: defineAsyncComponent(() => import('./views/StripeInfo.vue'))
  },
  name: 'StripeSection',
  props: {
    isUkPayment: {
      type: Boolean,
      default: false
    },
    isRecurringPayment: {
      type: Object,
      default: null
    },
    ukDailySadaqahSubscription: {
      type: Object,
      default: null
    },
    turnstileReturn: {
      type: String,
      default: null
    },
    checkCard: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hidePayment: {
      type: Boolean,
      default: false
    },
    hidePayButton: {
      type: Boolean,
      default: false
    },
    hidePayCard: {
      type: Boolean,
      default: false
    }
  },
  emits: ['stripeContinue', 'update:isLoading', 'sadaqahComplete', 'recurringComplete'],
  computed: {
    isLoadingValue: {
      get () {
        return this.isLoading
      },
      set (value) {
        this.$emit('update:isLoading', value)
      }
    }
  },
  methods: {
    async stripeContinue () {
      await this.$emit('stripeContinue')
    },
    sadaqahComplete () {
      this.$emit('sadaqahComplete', this.ukDailySadaqahSubscription)
    },
    recurringComplete () {
      this.$emit('recurringComplete')
    }
  }
})
</script>
